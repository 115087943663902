import { Link, useLocation, useNavigate, withRouter } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import "./Player.css";
import screenfull from "screenfull";
import {
  Col,
  Row,
  Dropdown,
  Button,
  Card,
  Spinner,
  Image,
} from "react-bootstrap";
import ReactPlayer from "react-player";
import { Slider, Direction } from "react-player-controls";
import {
  BsFillCaretRightFill,
  BsFillCaretLeftFill,
  BsBack,
  BsFillArrowLeftSquareFill,
  BsFillStarFill,
  BsFillCalendarFill,
  BsFillShareFill,
  BsFillPlayCircleFill,
  BsPlayFill,
} from "react-icons/bs"; // Import the BsFilter icon
import Cookies from "js-cookie";

// import axios from "axios";
// import Cookies from "js-cookie";
import instance, { APP_COLORS, APP_IMAGES } from "../common/Api";
import { useCookies } from "react-cookie";
import VideoJsPlayer from "./VideoJsPlayer";
import TempVideoPlayer from "./TempVideoPlayer";

// import CommonListing from '../common/CommonListing';

const MobilePlayer = (props) => {
  //   const location = useLocation();
  const { someData, type, userData, tappedUrl, tappedPoster, allData } = props;
  console.log(someData, "MOBILE");
  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }
  const [isVisible, setIsVisible] = useState(false);
  const [videoType, setVideoType] = useState(null);

  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    volumeOpen: false,
    dropdownOpen: false,
    fullscreen: false,
  });
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies();
  const [newCookies, setNewCookies] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [posterUrl, setPosterUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [cookies, setCookie] = useCookies(["key1", "key2"]);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [mediaData, setMediaData] = useState(null);
  const [watchNext, setWatchNext] = useState(null);
  const [loadingNext, setLoadingNext] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showContent, setShowContent] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );
  // const [bebucookies, setBebuCookies] = useState(null);
  // const [cookies, setCookie] = useCookies();
  let timeout;
  // const handleApiResponse = (response) => {
  //   // Assuming the response is an array of objects
  //   response.forEach((obj) => {
  //     const { key, value } = obj;
  //     // console.info(key);
  //     // console.info(value);
  //     setCookie(key, value); // Set the cookie with the key and value from the API response
  //   });
  // };

  const [orientation, setOrientation] = useState(window.orientation);
  const set_Cookies = (key, value, options) => {
    Cookies.set(key, value, options);
  };
  useEffect(() => {
    (async function () {
      let response = await instance.get("/getAllCookies");

      response.data.forEach((obj) => {
        const { key, value } = obj;
        setCookies(key, value, { path: "/", domain: "bebu.app" });
      });
      //console.log(cookies);
    })();
  }, []);

  // useEffect(() => {
  //   const setNewCookies = async () => {
  //     try {
  //       instance
  //         .get(`/getAllCookies`)
  //         .then((response) => {
  //           const cookies = response.data;
  //           // console.log("getAllCookies.....", cookies);

  //           cookies.forEach((obj) => {
  //             const { key, value } = obj;
  //             setCookies(key, value, { path: "/play", domain: "bebu.app" });
  //             document.cookie = `${key}=${value}; path=/play`;
  //             console.log(key, value);
  //           });
  //           getCurrentCookies();
  //           // Object.entries(cookies).forEach(([key, value]) => {
  //           //   document.cookie = `${key}=${value}; path=/play`;
  //           // });
  //           // setNewCookies(cookies);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching data:", error);
  //         });
  //       // Fetch data from an endpoint (example)
  //     } catch (error) {
  //       console.error("Error fetching or setting cookies:", error);
  //     }
  //   };

  //   setNewCookies();
  // }, []);

  const checkSubscribtion = () => {
    if (userData != null) {
      instance
        .post(`/subscription_plans_web?id=${userData.id}&ip=${props.ip}`)
        .then((response) => {
          //console.log("response.data", response.data);
          const active = response.data.active_plan;
          // console.log(active[0], "<active");
          if (response.data && response.data.active_plan.length > 0) {
            // setActivePlans(response.data.active_plan[0]);
            setIsSubscribed(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
      //console.log("User ID:", props.userData.id);
    } else {
      //console.log("User ID not found");
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (userData !== null) {
    //   setIsSubscribed(props.userData.isSubscribed);
    // }
    checkSubscribtion();
    const handleOrientationChange = () => {
      setOrientation(window.orientation);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    // console.warn(orientation);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, [orientation]);

  useEffect(() => {
    setShowContent(window.matchMedia("(max-width: 800px)").matches);
    instance
      .get(
        `/videoViewBySlug?titleYearSlug=${someData.title}&videoid=${someData.id}`
      )
      .then((response) => {
        setMediaData(response.data);
        //console.log("response.data", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });

    //console.log("SET URL");

    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    let timeout;
    if (isVisible && state.playing) {
      timeout = setTimeout(() => {
        setIsVisible(false);
      }, 5000); // 5 seconds in milliseconds
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible, showContent]);

  useEffect(() => {
    if (userData !== null) {
      // console.warn(userData.id);
      instance
        .post(`/suggestions?id=${userData.id}&admin_video_id=${146}&skip=1`)
        .then((response) => {
          //console.log(response.data.data);
          setWatchNext(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoadingNext(false);
        });
      if (watchNext) {
        //console.log(watchNext);
      }
    } else {
      navigate("/subscribe");
    }
  }, []);

  // const player = useRef(null);

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };
  const getCurrentCookies = () => {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    console.log(cookies, "current coookies on player");
    setNewCookies(cookies);

    // cookies.forEach((cookie) => {
    //   const [key, value] = cookie.split("=");

    //   console.log(key, value, "current coookies on player");
    // });
  };
  const [hovered, setHovered] = useState(false);
  // const history = useHistory();
  const handleMouseEnter = () => {
    if (state.playing) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleButtonClick = () => {
    // history.push('/play');
  };

  const containsTrailer = (str) => {
    return str.includes("Trailer");
  };

  const handleClickWatch = (item) => {
    // instance
    //   .get(
    //     `/videoViewBySlug?titleYearSlug=${item.title}&videoid=${item.id}`
    //   )
    //   .then((response) => {
    //     setMediaData(response.data);
    //     //console.log("response.data", response.data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   })
    //   .finally(() => {
    //     setLoading(false); // Set loading to false when fetch is complete
    //   });
  };

  const handleVideoChange = (newVideoUrl, newPoster) => {
    const containsTrailer = newVideoUrl && newVideoUrl.includes("Trailer");
    if (userData !== null) {
      // console.log("handleVideoChange", newVideoUrl);
      if (isSubscribed) {
        if (containsTrailer) {
          setPosterUrl(newPoster);
          setVideoUrl(newVideoUrl); //`""`);// "https://vz-b4f1e97e-483.b-cdn.net/65c65840-de66-4c27-afd0-a3b5a904b768/playlist.m3u8"
        } else if (isSubscribed) {
          setPosterUrl(newPoster);
          setVideoUrl(newVideoUrl); //`""`);// "https://vz-b4f1e97e-483.b-cdn.net/65c65840-de66-4c27-afd0-a3b5a904b768/playlist.m3u8"
        } else {
          // navigate("/subscribe");
        }
      } else {
        navigate("/subscribe");
      }
    } else {
      navigate("/login");
    }

    // if (userData != null) {
    //   setVideoUrl(newVideoUrl);
    //   setPosterUrl(newPoster);
    // } else {
    //   navigate("/login");
    // }
  };

  // const skipBackward = (event) => {
  //   event.preventDefault();
  //   if (player.current) {
  //     const currentTime = player.current.getCurrentTime();
  //     const newTime = currentTime - 10;
  //     player.current.seekTo(newTime, "seconds");
  //     // setState({ ...state, playing: true });
  //   }
  // };

  // const skipForward = (event) => {
  //   event.preventDefault();
  //   if (player.current) {
  //     const currentTime = player.current.getCurrentTime();
  //     const newTime = currentTime + 10;
  //     player.current.seekTo(newTime, "seconds");
  //     // setState({ ...state, playing: true });
  //   }
  // };
  const formatGenresWeb = (genres) => {
    if (!genres || genres.length === 0) {
      return null;
    }

    return genres.join(" | ");
  };
  const getCastNames = (cast) => {
    return cast.map((item) => item.name).join(" | ");
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const formatGenres = (genres) => {
    if (!genres || genres.length === 0) {
      return null;
    }
    return genres.join(", ");
  };

  const createVideoData = (item) => {
    // console.log(item.title, item.admin_video_id);
    setLoading(true);
    instance
      .get(`/videoViewBySlug?titleYearSlug=${item.title}&videoid=${item.id}`)
      .then((response) => {
        setMediaData(response.data);
        // console.log("response.data: createVideoData>", response.data);
        navigate("/play", { state: { someData: response.data } });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // if (mediaData !== null) {
        //   navigate("/play", { state: { someData: mediaData } });
        // }
        // setLoading(false);
        // Set loading to false when fetch is complete
      });
  };

  return (
    <div
      style={
        props.isMobile
          ? {
              width: "100%",
              minHeight: "55rem",
              // position: "relative",
              backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_MOB})`,
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
          : {
              minHeight: "55rem",
              backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
      }
    >
      {mediaData && (
        <div
          className={`${
            orientation == 0
              ? "player-container-mobile"
              : "player-container-mobile"
          }`}
          onClick={handleClick}
        >
          {videoUrl !== "" ? (
            <div>
              {/* <div>
                <ReactPlayer
                  url={`https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8`}
                  controls={true}
                  width="100%"
                  height="auto"
                  // config={{
                  //   hlsOptions: {
                  //     withCredentials: true,
                  //   },
                  // }}
                />
              </div> */}
              <VideoJsPlayer
                src={videoUrl}
                // videoUrl} //== "" ? mediaData.video : videoUrl}
                // `https://vz-b4f1e97e-483.b-cdn.net/65c65840-de66-4c27-afd0-a3b5a904b768/playlist.m3u8`}
                // videoUrl == "" ? mediaData.video : videoUrl}
                // `https://vz-b4f1e97e-483.b-cdn.net/65c65840-de66-4c27-afd0-a3b5a904b768/playlist.m3u8`}
                // videoUrl == "" ? mediaData.video : videoUrl}
                // "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"}
                poster={posterUrl} // == null ? mediaData.mobile_image : posterUrl}
                className="react-player"
                isMobile={isMobile}
                handleVideoChange={handleVideoChange}
                userData={userData}
                COOKIES={newCookies}
              />{" "}
            </div>
          ) : (
            <div style={{ position: "relative", display: "inline-block" }}>
              <Image
                style={{ borderRadius: "8px", padding: "10px" }}
                src={mediaData.mobile_image}
                fluid
                onClick={() =>
                  handleVideoChange(mediaData.video, mediaData.mobile_image)
                }
              />
              <div className="overlay"></div>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <button
                  className="loader-mobile"
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundImage: `url(./play-button.jpg)`,
                    backgroundSize: "cover",
                    border: "none",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                  }}
                  onClick={() =>
                    handleVideoChange(mediaData.video, mediaData.mobile_image)
                  }
                ></button>
              </div>
            </div>
          )}
          {orientation == 0 && (
            <div
              className="dynamic-content"
              style={
                videoUrl !== ""
                  ? { marginTop: orientation == 0 ? "65%" : "" }
                  : { marginTop: orientation == 0 ? "1rem" : "" }
              }
            >
              {/* {newCookies &&
                newCookies.map((item, index) => (
                  <p key={index}>
                    <strong>{item.key}:</strong> {item.value}
                  </p>
                ))} */}
              <Row>
                <Col className="col-md-6">
                  <h5
                    style={{
                      color: "white",
                      paddingLeft: "10px",
                      marginTop: mediaData.title.length > 20 ? "-10px" : "-2px",
                    }}
                  >
                    {mediaData && mediaData.title}
                  </h5>
                </Col>

                <Col
                  className="col-md-6"
                  style={{ marginTop: "-18px", marginRight: "0rem" }}
                >
                  <Button
                    style={{
                      backgroundColor: "#00000000",
                      borderWidth: "0",
                      color: APP_COLORS.APP_DARK_1,
                    }}
                  >
                    <BsFillShareFill />
                  </Button>{" "}
                  <Button
                    variant="info"
                    style={{
                      backgroundColor: APP_COLORS.APP_DARK_1,
                      color: "white",
                      width: "75%",
                      fontWeight: "700",
                      border: "none",
                    }}
                    onClick={() => handleVideoChange(mediaData.trailer_video)}
                  >
                    Watch Trailer
                    {/* {videoType === "main" ? "Watch Trailer" : "Play Episode"} */}
                  </Button>
                </Col>
              </Row>
              {/* <Row>
              <Col>
                <h1 style={{ color: "white", paddingLeft: "10px" }}>
                  {someData.title}
                </h1>
              </Col>
            </Row> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "15px",
                    marginLeft: "10px",
                    color: "white",
                    padding: "5px",
                    // backgroundColor: "#008dc4",
                    width: "50px",
                    marginRight: "2px", // Adjust margin as needed
                  }}
                >
                  U/A
                </h6>
                <h6
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    color: "white",
                  }}
                >
                  {mediaData && formatGenres(mediaData.geners1)}
                </h6>
              </div>
              <p
                style={{
                  color: "white",
                  fontSize: "12px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  textAlign: "justify",
                  marginBottom: "0.25rem",
                }}
              >
                {someData.description}
              </p>
              {/* <Col md={12} className="pb-1">
              <Button
                variant="primary"
                style={{
                  height: "40px",
                  width: "auto",
                  backgroundColor: "#58b7aa",
                  borderWidth: "0",
                  marginLeft: "10px",
                }}
                // onClick={() => setCurrentVideoUrl(mediaData.video)}
                onClick={handleClick}
              >
                <BsPlayFill
                  size={20}
                  className="mr-2"
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                />
                Play
              </Button>
              <Button
                variant="primary"
                // onClick={handleShow}
                style={{
                  marginLeft: "10px",
                  height: "40px",
                  width: "auto",
                  backgroundColor: "#58b7aa",
                  borderWidth: "0",
                }}
                // onClick={() => setCurrentVideoUrl(mediaData.trailer_video)}
                // onClick={handleClickTrailer}
              >
                <BsFillPlayCircleFill
                  size={20}
                  className="mr-2"
                  style={{ marginBottom: "4px", marginRight: "5px" }}
                />
                Watch Trailer
              </Button>
            </Col> */}
              <h6
                style={{
                  color: "white",
                  padding: "10px",
                  marginBottom: "0px",
                  fontSize: "0.9rem",
                  textAlign: "justify",
                }}
              >
                Director:&nbsp; &nbsp; {mediaData && mediaData.director}
              </h6>
              <h6
                style={{
                  color: "white",
                  paddingLeft: "10px",
                  marginBottom: "0px",
                  fontSize: "0.9rem",
                  textAlign: "justify",
                }}
              >
                Cast:&nbsp; {mediaData && getCastNames(mediaData.cast)}
              </h6>

              {mediaData &&
              mediaData.seasons1 &&
              Array.isArray(mediaData.seasons1[1]) ? (
                <Button
                  variant="info"
                  style={{
                    marginLeft: "10px",
                    backgroundColor: APP_COLORS.APP_DARK_1,
                    color: "white",
                    border: "none",
                  }}
                >
                  Season 1
                </Button>
              ) : null}

              {mediaData &&
              mediaData.seasons2 &&
              Array.isArray(mediaData.seasons2[1]) ? (
                <Button
                  variant="info"
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#1FB9A9",
                    color: "white",
                  }}
                >
                  Season 1
                </Button>
              ) : null}

              {/* <CommonListing items={items} /> */}
              <div className="movie-list" style={{ marginBottom: "-10px" }}>
                {mediaData &&
                mediaData.seasons1 &&
                Array.isArray(mediaData.seasons1[1])
                  ? mediaData.seasons1[1].map((item, index) => (
                      <Card
                        key={index}
                        className="movie-item"
                        style={{
                          backgroundColor: "#14141400",
                          position: "relative",
                          // paddingBottom: "2rem",
                        }}
                        onClick={() =>
                          handleVideoChange(item.video, item.mobile_image)
                        }
                      >
                        <Card.Img variant="top" src={item.mobile_image} />
                        <p style={{ fontSize: "small", color: "white" }}>
                          {item.ep_title}
                        </p>
                      </Card>
                    ))
                  : null}
              </div>
              {userData != null ? (
                <>
                  <h4
                    style={{
                      color: "#e20788",
                      marginBottom: "0",
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Watch Next
                  </h4>
                  <div
                    className="movie-list-next"
                    style={{ paddingBottom: "4rem" }}
                  >
                    {watchNext
                      ? watchNext.map((item, index) => (
                          <Card
                            key={index}
                            className="movie-item-next"
                            style={{
                              backgroundColor: "#14141400",
                              position: "relative",
                              paddingBottom: "5rem",
                            }}
                            onClick={() => createVideoData(item)}
                          >
                            <Card.Img
                              variant="top"
                              src={item.mobile_image}
                              style={{
                                objectFit: "cover",
                                width: "50%",
                                paddingRight: "0.7rem",
                                paddingBottom: "0.7rem",
                                borderRadius: "20px",
                                padding: "10px",
                              }}
                            />
                            {/* <p
                      className="text-white"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "10px",
                      }}
                    >
                      {item.title}
                    </p> */}
                          </Card>
                        ))
                      : null}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* <div className="dynamic-content-player">
            <Row>
              <Col className="col-md-6">
                <h5 style={{ color: "white", paddingLeft: "10px" }}>
                  {mediaData && mediaData.title}
                </h5>
              </Col>
              <Col className="col-md-6" style={{ marginLeft: "30px" }}>
                <Button
                  style={{
                    backgroundColor: "#00000000",
                    borderWidth: "0",
                    color: "#1FB9A9",
                  }}
                >
                  <BsFillShareFill />
                </Button>{" "}
                <Button
                  variant="info"
                  style={{ backgroundColor: "#1FB9A9" }}
                  onClick={() => handleVideoChange(mediaData.trailer_video)}
                >
                  Watch Trailer */}
          {/* {videoType === "main" ? "Watch Trailer" : "Play Episode"} */}
          {/* </Button>
              </Col>
            </Row>

            <h6 style={{ color: "#1FB9A9", paddingLeft: "10px" }}>
              {mediaData && mediaData.director}
            </h6>
            <p
              style={{
                color: "white",
                fontSize: "14px",
                padding: "10px",
                textAlign: "left",
              }}
            >
              {mediaData && mediaData.description}
            </p>
            <h6 style={{ color: "#1FB9A9", padding: "10px" }}>Info</h6>
            <h6 style={{ color: "white", padding: "10px" }}>
              Director: {mediaData && mediaData.director}
            </h6>
            <h6 style={{ color: "white", paddingLeft: "10px" }}>
              Cast: {getCastNames(mediaData && mediaData.cast)}
            </h6>
            <h6 style={{ color: "white", padding: "10px" }}>
              U/A | {formatGenresWeb(mediaData && mediaData.geners1)}
            </h6>
            {mediaData &&
            mediaData.seasons1 &&
            Array.isArray(mediaData.seasons1[1]) ? (
              <Button
                variant="info"
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#1FB9A9",
                  color: "white",
                }}
              >
                Season 1
              </Button>
            ) : null}
            <div className="movie-list">
              {mediaData &&
              mediaData.seasons1 &&
              Array.isArray(mediaData.seasons1[1])
                ? mediaData.seasons1[1].map((item, index) => (
                    <Card
                      key={index}
                      className="movie-item"
                      style={{
                        backgroundColor: "#14141400",
                        position: "relative",
                        paddingBottom: "5rem",
                      }}
                      onClick={() =>
                        handleVideoChange(item.video, item.mobile_image)
                      }
                    >
                      <Card.Img variant="top" src={item.mobile_image} />
                      <p
                        className="text-white"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "10px",
                        }}
                      ></p>
                    </Card>
                  ))
                : null}
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default MobilePlayer;
